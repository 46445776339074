const AdminPage: React.FC = () => {
	return (
		<div>
			<h1>Admin Dashboard</h1>
			<p></p>
		</div>
	);
};

export default AdminPage;
